@import 'styles/utils';

.wrapper {
  padding-left: 3%;
  padding-right: 3%;
  width: 100%;
  margin-top: -75px;
  display: flex;
  overflow: auto;
  position: relative;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.background {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  height: 300px;
  width: 286px;
  border-radius: 4px;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.5));
  @include breakpoint('lg') {
    width: 100%;
  }
}

.slideWrapper {
  position: relative;
  @include breakpoint('lg') {
    flex: 0 0 31%;
  }
}

.image {
  display: block;
  height: 300px;
  width: 286px;
  border-radius: 4px;
  z-index: 0;
  object-fit: cover;
  @include breakpoint('lg') {
    width: 100%;
  }
}

.imageContent {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 20px;
  color: $white;
  z-index: 1;
}

.name {
  margin-bottom: 6px;
  line-height: 30px;
  display: block;
  @include breakpoint('lg') {
    line-height: 32px;
  }
}

.link {
  text-decoration: none;
  color: $starcar-yellow;
  display: flex;
  align-items: center;
  width: fit-content;
}

.icon {
  height: 24px;
  width: 24px;
}

.sliderIcon {
  height: 40px;
  width: 40px;
}

.scrollButtonRight {
  cursor: pointer;
  position: absolute;
  display: none;
  right: 0.75%;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  @include breakpoint('lg') {
    display: block;
  }
  @include breakpoint('xl') {
    right: 1%;
  }
  @include breakpoint('xxl') {
    right: 1.5%;
  }
}

.scrollButtonLeft {
  cursor: pointer;
  position: absolute;
  display: none;
  left: 0.75%;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  @include breakpoint('lg') {
    display: block;
  }
  @include breakpoint('xl') {
    left: 1%;
  }
  @include breakpoint('xxl') {
    left: 1.5%;
  }
}

.linkWrapper {
  text-decoration: none;
  color: inherit;
}

.linkWrapper:hover .link {
  transform: translateX(10px);
  transition: transform 0.3s ease;
}
